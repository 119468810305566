import { useCallback, useState, ReactNode } from 'react';
import { PrescriptionFormValues } from '../types/firestore';
import { UseFormReturnType } from '@mantine/form';
import { Anchor } from '@mantine/core';
import { styled } from 'styled-components';

const StyledLink = styled(Anchor)`
  color: #c9d5dc;
  text-align: left;
  font-size: 0.9rem;
  line-height: normal;
` as typeof Anchor;

export const useHandleLEMI = (form: UseFormReturnType<PrescriptionFormValues>) => {
  const [LEMIComponent, setLEMIComponent] = useState<ReactNode>(null);
  const { setFieldValue } = form;

  const handleLEMIInfo = useCallback(
    ({ isLemi, isLmbc }: { isLemi: boolean; isLmbc: boolean }) => {
      if (isLemi) {
        // Medicine is recommended to prescribe by brand only
        setFieldValue('drugData.brandOnly', true);
        setFieldValue('drugData.includeBrand', false);
        setLEMIComponent(
          <span>
            This item is included on the{' '}
            <a
              target="_blank"
              href="https://www.health.gov.au/our-work/active-ingredient-prescribing/lemi-lmbc/lemi"
              rel="noopener noreferrer"
            >
              List of Excluded Medicinal Items (LEMI)
            </a>
            , and should be prescribed by brand name only for practical and safety reasons.
          </span>,
        );
      } else if (isLmbc) {
        // Medicine is recommended to have brand name included
        setFieldValue('drugData.brandOnly', false);
        setFieldValue('drugData.includeBrand', true);
        setLEMIComponent(
          <span>
            This item is included on the{' '}
            <a
              target="_blank"
              href="https://www.health.gov.au/our-work/active-ingredient-prescribing/lemi-lmbc/lmbc"
              rel="noopener noreferrer"
            >
              List of Medicines for Brand Consideration (LMBC)
            </a>
            . Prescribers should consider prescribing by brand as well as active ingredient for
            patient safety.
          </span>,
        );
      } else {
        // Neither LEMI nor LMBC listed; prescribe by active ingredient only
        setFieldValue('drugData.brandOnly', false);
        setFieldValue('drugData.includeBrand', false);
        setLEMIComponent(
          <span>
            This item should be prescribed by active ingredient only. For more details, see{' '}
            <StyledLink
              href="https://www.health.gov.au/our-work/active-ingredient-prescribing/when-it-applies"
              target="_blank"
              rel="noreferrer"
            >
              when active ingredient prescribing applies
            </StyledLink>
            .
          </span>,
        );
      }
    },
    [setFieldValue],
  );

  /**
   * Remove LEMI info by setting the LEMI component to null. Used for (generally) non-PBS medications
   */
  const removeLEMIInfo = useCallback(() => {
    setLEMIComponent(null);
  }, []);

  return { LEMIComponent, handleLEMIInfo, removeLEMIInfo };
};
